var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bg-gray-900 bg-opacity-70 text-white"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"step2"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.catchSubmitEvent()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_logoUrl",attrs:{"name":"logo url","rules":{required: true, regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Logo URL")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.logoUrl},on:{"input":function($event){_vm.updateFormField('presale', null, 'logoUrl', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_website",attrs:{"name":"website","rules":{required: true, regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Website")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.website},on:{"input":function($event){_vm.updateFormField('presale', null, 'website', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_facebook",attrs:{"name":"facebook","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Facebook")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.facebook},on:{"input":function($event){_vm.updateFormField('presale', null, 'facebook', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_twitter",attrs:{"name":"twitter","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Twitter")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.twitter},on:{"input":function($event){_vm.updateFormField('presale', null, 'twitter', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_github",attrs:{"name":"github","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Github")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.github},on:{"input":function($event){_vm.updateFormField('presale', null, 'github', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_telegram",attrs:{"name":"telegram","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Telegram")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.telegram},on:{"input":function($event){_vm.updateFormField('presale', null, 'telegram', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_instagram",attrs:{"name":"instagram","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Instagram")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.instagram},on:{"input":function($event){_vm.updateFormField('presale', null, 'instagram', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('validation-provider',{ref:"presaleForm_discord",attrs:{"name":"discord","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Discord")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.discord},on:{"input":function($event){_vm.updateFormField('presale', null, 'discord', $event.target.value.trim())}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{ref:"presaleForm_reddit",attrs:{"name":"reddit","rules":{regex: _vm.expression.url}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Reddit")]),_c('input',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"type":"text","placeholder":"https://domain.ext"},domProps:{"value":_vm.forms.presale.reddit},on:{"input":function($event){return _vm.updateFormField('presale', null, 'reddit', $event.target.value)}}}),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{ref:"presaleForm_description",attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Description")]),_c('textarea',{staticClass:"form-control form-control-lg",class:{'is-invalid': errors[0] !== undefined},attrs:{"rows":"3","type":"text","placeholder":"Lorem ipsum..."},domProps:{"value":_vm.forms.presale.description},on:{"input":function($event){return _vm.updateFormField('presale', null, 'description', $event.target.value)}}}),_vm._v(" "),_c('span',{class:{'invalid-feedback': errors[0] !== undefined}},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-4"},[_c('button',{staticClass:"btn btn-secondary text-dark w-100",on:{"click":function($event){return _vm.$emit('previous');}}},[_vm._v("PREVIOUS")])]),_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4"},[_c('button',{staticClass:"btn btn-primary text-dark w-100",on:{"click":function($event){return _vm.validateAndAdvance()}}},[_vm._v("NEXT")])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }